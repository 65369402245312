<template> 
    <b-container id="media" fluid>
        <b-row class="media-item px-2 px-md-3 pt-4 align-items-end">
            <b-col class="col-12 col-md-6" v-for="mediaItem in mediaItems" :key="mediaItem.id">
                <div class="px-4 py-4">
                    <div v-if="mediaItem.format=='mp4'">
                        <video preload="none" controls :poster="mediaThumbnail(mediaItem.thumbnail)" 
                               class="mx-auto d-block">
                            <source :src="mediaPlay(mediaItem.filename)" type='audio/mp4'>
                            This cannot be played in your current browser.
                        </video>
                    </div>
                    <div v-if="mediaItem.format=='mp3'" class="audio-item"
                         :style="audioBackground(mediaThumbnail(mediaItem.thumbnail))">
                        <audio controls class="mx-auto d-block">
                            <source :src="mediaPlay(mediaItem.filename)" type='audio/mp3'>
                            This cannot be played in your current browser.
                        </audio>
                    </div>
                    <div class="media-item-title mx-auto pt-3">
                        <p>{{mediaItem.title}}</p>
                    </div>
                    <div class="media-item-text mx-auto">
                        <p>Composer: {{mediaItem.composer}}</p>
                    </div>
                    <div class="media-item-text mx-auto">
                         <p>Accompanist: {{mediaItem.accompanist}}</p>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

    import json from '@/assets/json/MediaItems.json'
    export default {
        name: 'Media',
        data() {
            return {
                mediaItems: json
            }
        },
        methods: {
            mediaThumbnail(filename) {
                const filepath = filename
                return require(`../assets/thumbnails/${filepath}`)
            },
            mediaPlay(filename) {
                const filepath = filename
                return require(`../assets/media/${filepath}`)
            },
            audioBackground(image) {
                return 'background-image: url("' + image + '")';
            }
        }
    }
</script>

<style scoped>

    #media {
        background-color:var(--media-background-colour);
        min-height:100px;
    }

    .media-item {
        border-style: none;
        border-width: thin;
    }

    video  {
        display: block;
        max-height: 300px;
        min-height: 250px;
        width: 100% !important;
        height: auto !important;
    }

    .audio-item {
        display: block;
        max-height: 300px;
        min-height: 250px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    .media-item-title {
        color:var(--media-title-colour);
    }

    .media-item-text {
        color: var(--media-text-colour);
    }

    @media (max-width: 544px) {
        .audio-item {
            height: 300px;
        }
    }

    @media (min-width: 544px) {
        .audio-item {
            height: 300px;
        }
    }

    @media (min-width: 768px) {
        .audio-item {
            height: 250px;
        }
    }

    @media (min-width: 992px) {
        .audio-item {
            height: 300px;
        }
    }

</style>


