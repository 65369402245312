<template>
    <div id="app" class="container-fluid m-0">
        <div id="nav" class="sticky-top">
            <!-- container-fluid makes navbar full width. p-0 removes padding round menu section -->
            <b-navbar class="navbar-light p-0" toggleable="md">
                <b-navbar-toggle target="catMenu" class="mx-auto"></b-navbar-toggle>

                <b-collapse id="catMenu" class="collapse navbar-collapse" is-nav>
                    <b-navbar-nav class="container-md w-auto fsMenu">
                        <router-link :to="{ name: 'Info', hash: '#app' }" class="nav-item nav-link mx-2">Home</router-link>
                        <router-link :to="{ name: 'Info', hash: '#info' }" class="nav-item nav-link mx-2">About</router-link>
                        <router-link :to="{ name: 'Info', hash: '#gallery' }" class="nav-item nav-link mx-2">Gallery</router-link>
                        <router-link :to="{ name: 'Media', hash: '#media' }" class="nav-item nav-link mx-2">Media</router-link>
                        <router-link :to="{ name: 'Repertoire', hash: '#repertoire' }" class="nav-item nav-link mx-2">Weddings</router-link>
                        <router-link :to="{ hash: '#contact' }" class="nav-item nav-link mx-2">Contact</router-link>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>

        </div>
        <div id="header">
            <Header />
        </div>
        <router-view></router-view>
        <div id="contact">
            <Contact />
        </div>
    </div>
</template>

<script>
    import Contact from './components/Contact.vue'
    import Header from './components/Header.vue'
    import Info from './components/Info.vue'
    import Media from './components/Media.vue'
    import Repertoire from './components/Repertoire.vue'

    export default {
        name: 'App',
        components: {
            Header,
            Info,
            Contact,
            Media,
            Repertoire
        },
        created() {
            document.title = "Catriona Holsgrove - Soprano";
        }
    }
</script>

<style>
    @import './assets/css/theme.css';

    #app {
        border: none;
        min-height: 100%;
        font-family: var(--font-family);
        color: var(--text-colour);
        background-color: var(--background-colour);
        padding: 0
    }

    #nav, .navbar-collapse, navbar-light {
        font-family: var(--font-family);
        background: var(--menu-background-colour);
        color: var(--menu-text-colour) !important;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    /* Link - size and position*/
    .nav-link {
        background: var(--menu-background-colour);
        display: inline-block;
        text-align: center;
    }

    #nav a {
        color: var(--menu-text-colour) !important;
    }

        #nav a:hover {
            color: var(--menu-hover-colour) !important;
        }
</style>