<template>
    <div id="gallery"> 
        <div id="gallery_large" class="d-none d-md-block">
            <b-carousel id="gallery_carousel" class="photo-gallery" controls background="var(--gallery-background-colour)">
                <div class="carousel-item " v-for="slideGroup in galleryPhotos" :key="slideGroup.photoGroupId">
                    <template(v-slot:img)>
                        <b-container fluid>
                            <b-row class="my-auto">
                                <b-col cols="1" class="fsText4">
                                    {{ slideGroup.photoGroupName }}
                                </b-col>
                                <b-col v-for="imgsrc in slideGroup.photoGroup" :key="imgsrc.id">
                                    <div class="slide_frame d-flex align-items-center">
                                        <b-img class="d-block img-fluid mx-auto"
                                               v-bind:class="{ slide_img_landscape: imgsrc.orientation=='L', slide_img_portrait: imgsrc.orientation=='P' }"
                                               :src=galleryImage(imgsrc.filename) v-bind:alt=imgsrc.filename />
                                    </div>
                                </b-col>
                                <b-col cols="1"></b-col>
                            </b-row>
                        </b-container>
                    </template(v-slot:img)>
                </div>
            </b-carousel>
        </div>
        <div id="gallery_small" class="d-block d-md-none">
            <b-carousel id="gallery_carousel_small" class="photo-gallery" controls background="var(--gallery-background-colour)">
                <div class="carousel-item" v-for="slide in slideImages(galleryPhotos)" :key="slide.id">
                    <template(v-slot:img)>
                        <b-container fluid>
                            <b-row class="my-auto">
                                <b-col class="mx-auto">
                                    <div class="slide_frame d-flex align-items-center">
                                        <b-img class="carousel-slide d-block img-fluid mx-auto"
                                               v-bind:class="{ slide_img_landscape: slide.orientation=='L', slide_img_portrait: slide.orientation=='P' }"
                                               :src=galleryImage(slide.filename) v-bind:alt=slide.filename />
                                    </div>  
                                    <div class="caption fsText4 text-center">
                                        {{ slide.photoGroupName}}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </template(v-slot:img)>
                </div>
            </b-carousel>
        </div>
    </div>
</template>
<script>
    import json from '@/assets/json/GalleryItems.json'
    export default {
        name: 'Gallery',
        data() {
            return {
                galleryPhotos: json
            }
        },
        methods: {
            galleryImage(filename) {
                const filepath = filename
                return require(`../assets/photos/gallery/${filepath}`)
            },
            /* Flatten the json for a smaller screen */
            slideImages(gallery) {
                return gallery.map(x => x.photoGroup.map(g => Object.assign(g, { photoGroupName: x.photoGroupName }))).reduce((a, b) => a.concat(b), [])
            }
        }
    }
</script>
<style scoped>
    /* Determine the height of the carousel*/
    .photo-gallery {
        padding: 2%;
    }
    .slide_img_portrait {
        height: 100%;
        width: auto;
    }
    .slide_img_landscape {
        width: 100%;
        height: auto;
    }
    @media (max-width: 544px) {
        .slide_frame {
            height: 340px;
        }
    }
    @media (max-width: 768px) {
        .slide_frame {
            height: 340px;
        }

        .slide_img_landscape {
            width: 70%;
            height: auto;
            max-height: 340px;
        }
    }
    @media (min-width: 768px) {
        .slide_frame {
            height: 180px;
        }
        .photo-gallery {
            padding: 1%;
        }
    }
    @media (min-width: 992px) {
        .slide_frame {
            height: 250px;
        }
    }
    @media (min-width: 1200px) {
        .slide_frame {
            height: 300px;
        }
    }
</style>
