<template>
    <div id="repertoire" class="container-fluid pt-5 px-5">
        <div class="row pt-2">
            <div class="col-md-4 col-0"></div>
            <div id="title" class="col-md-6 col-12 fsTitle">
                Wedding Repertoire
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4 col-0"></div>
            <div class="col-md-6 col-12 ml-md-4 fs-Text1">
                <p>Lascia ch’io Pianga – Handel, Rinaldo</p>
                <p>Ombra mai fu – Handel, Serse</p>
                <p>Where e’er you walk – Handel, Semele</p>
                <p>Deh Vieni – Mozart, Le Nozze di Figaro</p>
                <p>I know my redeemer liveth – Handel, Messiah</p>
                <p>Let the bright seraphim – Handel, Samson</p>
                <p>Gloria in excelsis Deo – Handel</p>
                <ul>
                    <li>I - Gloria in excelsis Deo</li>
                    <li>II – Et in terra pax</li>
                    <li>III – Laudamus te</li>
                    <li>IV – Domine Deus</li>
                    <li>V – Qui tollis peccata mundi</li>
                    <li>VI – Quoniam cum sancto spiritu</li>
                </ul>
                <p>Jesu, Joy of Man’s Desiring – Bach, BMV 147</p>
                <p>Agnus Dei – Mozart, Coronation Mass</p>
                <p>Laudate Dominum – Mozart, Vesperae solennes de confessore</p>
                <p>Alleluia – Mozart, Exsultate, Jubilate</p>
                <p>Ave Maria – Schubert</p>
                <p>Ave Maria – Bach/Gounod</p>
                <p>Pie Jesu – Lloyd Webber</p>
                <p>Pie Jesu – Faure</p>
                <p>Panis Angelicus – Franck</p>
                <p>Widmung – Schumann</p>
            </div>
        </div>
        <p class="text-center pb-4 fs-Text2">This list is non-exhaustive. Catriona is happy to learn new repertoire upon request.</p>
    </div>
</template>


<script>
    export default {
        name: 'Repertoire'
    }
</script>

<style scoped>

    #repertoire {
        background-color: var(--repertoire-background-colour)
    }

    #title {
        color: var(--repertoire-title-colour)
    }

</style>