 <template>
    <!-- Container-fluid m-0 with row makes the image full width -->
    <div id="header" class="container-fluid d-block m-0">
        <div class="row">
            <!-- Give a height and width to the image so that the text is displayed correctly before the picture is loaded -->
            <img :src="HeaderImage" class="img-fluid w-100" width="1" height="1"/>
            <div class="header-title carousel-caption text-left fsTitle py-0 py-md-5">
                <p>Catriona Holsgrove</p>
                <p>Soprano</p>
            </div>
        </div>
     </div>
</template>

<script>

    export default {
        name: 'Header',
        computed: {
            HeaderImage() {
                return require(`@/assets/photos/heading.jpg`)
            }
        },
    } 

</script>  

<style scoped>
    #header {
        position: relative;
        background-color: var(--background-colour);
        color: var(--title-colour);
    }

    .header-title {
        position: absolute;
        top: 5%;
        left: 5%;
        color: var(--title-colour);
    }
</style>