<template>
    <div id="contact" class="container-fluid m-0 pl-md-5">
        <div class="row">
            <div class="col-12 col-md-2 align-items-center ">
                <SocialMedia />
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <Email />
            </div>
        </div>
    </div>
</template>

<script>
    import SocialMedia from './SocialMedia.vue'
    import Email from './Email.vue'

    export default {
        name: 'Contact',
        components: {
            SocialMedia,
            Email
        }
    }
</script>

<style scoped>
    #contact {
        background-color: var(--background-colour)
    }
</style>