<template>
    <div id="info">
        <About/>
        <div id="gallery">
            <Gallery />
        </div>
    </div>
</template>

<script>
    import About from './About.vue'
    import Gallery from './Gallery.vue'

    export default {
        name: 'Home',
        components: {
            About,
            Gallery
        }
    }
</script>
