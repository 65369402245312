<template>
    <div class="pt-5">
        <footer class="navbar navbar-custom navbar-expand pb-2 pb-sm-3">
            <ul class="navbar-nav mx-auto mx-md-0">
                <li>
                    <a href="https://www.facebook.com/Catriona-Holsgrove-Soprano-192021594737512/">
                        <font-awesome-icon :icon="['fab', 'facebook']" class="icon-circle fa-facebook" />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/catrionaholsgrove">
                        <font-awesome-icon :icon="['fab', 'instagram']" class="icon-circle fa-instagram fa-padding" />
                    </a>
                </li>
            </ul>
        </footer>
    </div>
</template>

<script>

    export default {
        name: 'SocialMedia'
    }
</script>

<style scoped>
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }

    .icon-circle {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        line-height: 50px;
        margin: auto 4px;
        text-align: center;
    }

    /* Add a hover effect if you want */
    .fa:hover {
        opacity: 0.7
    }

    /* Set a specific colour for each brand */

    /* Facebook */
    .fa-facebook {
        color: #3B5998;
        background-color: white;
    }

    .fa-instagram {
        color:white;
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    }

    /* Twitter */
    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    .fa-envelope {
        height: 50px;
        width: 50px;
        color: white;
        margin: auto 4px;
    }

    .fa-padding {
        padding: 7px;
    }

    @media screen and (max-width: 575px) {

        .icon-circle {
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: auto 4px;
            font-size: 20px;
        }

        .fa-envelope {
            height: 30px;
            width: 30px;
            color: white;
            margin: auto 4px;
        }

        .fa-padding {
            padding: 4px;
        }
    }
</style>