import { render, staticRenderFns } from "./Email.vue?vue&type=template&id=ff031a9e&scoped=true"
import script from "./Email.vue?vue&type=script&lang=js"
export * from "./Email.vue?vue&type=script&lang=js"
import style0 from "./Email.vue?vue&type=style&index=0&id=ff031a9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff031a9e",
  null
  
)

export default component.exports