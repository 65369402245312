<template>
    <div id="email">
        <form id="contactForm" class="contact-form fsText3 p-5" @submit.prevent="submitForm">
            <div class="container-fluid">
                <b-row class="p-2">
                    <b-col class="d-none d-md-block col-md-3">
                        <label for="name">Name</label>
                    </b-col>
                    <input type="text" name="name" placeholder="Name" v-model="name"
                           class="col-12 col-md-9 text-input">
                </b-row>

                <b-row class="p-2">
                    <b-col class="d-none d-md-block col-md-3">
                        <label for="email">Email</label>
                    </b-col>
                    <input type="email" name="email" placeholder="Email Address" v-model="email"
                           class="col-12 col-md-9 text-input">
                </b-row>

                <b-row class="p-2">
                    <b-col class="d-none d-md-block col-md-3">
                        <label for="message">Message</label>
                    </b-col>
                    <textarea name="message" placeholder="Message" v-model="message"
                              class="col-12 col-md-9 text-input"
                              rows="4"></textarea>
                </b-row>

                <b-row class="p-2">
                    <b-col class="d-none d-md-block col-md-3" />
                    <input type="submit" value="Send" class="col-12 col-md-9 text-input" />
                </b-row>

                <b-row class="p-2">
                    <b-col class="d-none d-md-block col-md-3" />
                    <div v-bind:error="response" class="col-12  col-md-9 text-white" v-if="response">
                        {{response}}
                    </div>
                </b-row>

            </div>
        </form>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Contact',
        data() {
            return {
                response: "",
                name: "",
                email: "",
                message: ""
            }
        },
        methods: {
            submitForm() {
                var that = this;
                if (this.name && this.email && this.message) {
                    axios({
                        method: "post",
                        url: '.\\scripts\\sendmail.php',
                        data: {
                            name: that.name,
                            email: that.email,
                            message: that.message
                        }
                    }).then(function () {
                        that.response = "Your message has been sent.";
                        that.name = that.email = that.message = "";
                    }).catch(function (error) {
                        that.response = error.message;
                    })
                }
                else {
                    that.response = "Please complete all fields to send a message."
                }
            }
        }
    }

</script>

<style scoped>
    .contact-form {
        background-color: var(--background-colour);
    }

    .text-input {
        background-color: var(--email-background-colour);
    }
</style>


