<template>
    <div id="about" class="container-fluid pt-md-5 px-5">

        <div id="AboutTitle" class="row d-flex align-items-center">
            <div class="col-md-4 d-md-block d-none mx-auto my-auto">
                <img :src="Image1" class="img-fluid headshot-size" />
            </div>
            <div class="col-12 col-md-8 d-block p-3 fsText2">
                <div class="row p-md-3">
                    <p>
                        Soprano Catriona Holsgrove is a graduate of the Royal Academy of Music, London
                        where she studied with Raymond Connell and Iain Ledingham,
                        and, in 2019, gained a Master of Arts degree with distinction.
                    </p>
                    <p>
                        She sings with ensembles including &nbsp;<a href="https://www.london-voices.com/">London Voices</a>,&nbsp;
                        <a href="https://www.tenebrae-choir.com//">Tenebrae</a>,&nbsp;
                        <a href="https://www.marianconsort.co.uk/">The Marian Consort</a> and &nbsp;
                        <a href="https://englishconcert.co.uk/">The English Concert</a>, and also with various church choirs including the Choir of the London Oratory.
                    </p>
                    <p>
                        Catriona also sings with the <a href="https://www.lerionconsort.com/">Lerion Consort</a>, a professional vocal ensemble founded and conducted by Joe Tobin,
                        and is their General Manager.
                    </p>
                    <p>
                        Catriona is available to give private Singing Lessons. If you are interested, please <router-link :to="{ hash: '#contact' }">get in touch</router-link>.
                    </p>
                </div>
            </div>
            <div class="col-12 d-block d-md-none mx-auto my-auto">
                <img :src="Image1" class="img-fluid headshot-size" />
            </div>
        </div>

        <div id="AboutRAM" class="row d-flex fsText2 align-items-center">
             <div class="col-12 col-md-7 d-block p-3">
                <div class="row p-md-3">
                    <p>
                        Catriona was a Boise Foundation Scholar 2017 and during her time at the Academy, she was selected by the Josephine Baker Trust as one of their approved artists.
                    </p>
                    <p>
                        She was runner up and very highly commended in the prestigious Major Van Someren-Godfrey Memorial Prize for English Song.
                    </p>
                    <p>
                        She was a member of the choir for the Royal Academy of Music’s Bach the European series and the soloist for Handel’s <i>Gloria</i>.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-5 d-block mx-auto my-auto">
                <img :src="Image3" class="img-fluid photo-size" />
            </div>
        </div>

        <div id="AboutCardiff" class="row d-flex align-items-center">
            <div class="col-md-4 d-md-block d-none mx-auto my-auto">
                <img :src="Image2" class="img-fluid photo-size" />
            </div>
            <div class="col-12 col-md-8 d-block fsText2 p-3">
                <div class="row p-md-3">
                    <p>
                        Before moving to London, Catriona read Music at Cardiff University, where she was
                        the recipient of the David Lloyd Scholarship and on graduating, was awarded the
                        Sir Geraint Evans Prize.
                    </p>
                    <p>
                        Whilst at Cardiff, she was a member of <a href="https://thesixteen.com/genesis-sixteen/">Genesis Sixteen</a>
                        and a Scholar with the <a href=https://www.bbc.co.uk/bbcnow>BBC National Chorus of Wales</a>.
                    </p>
                </div>
            </div>
            <div class="col-12 d-block d-md-none mx-auto my-auto">
                <img :src="Image2" class="img-fluid photo-size" />
            </div>
        </div>

        <div id="AboutGlos" class="row d-flex fsText2 align-items-center">
            <div class="col-12 col-md-7 d-block p-3">
                <div class="row p-md-3">
                    <p>
                        Originally from Gloucester, Catriona began singing with the Gloucester Cathedral Youth Choir
                        and was the winner of the Keith Nutland Award at the Cheltenham Festival.
                    </p>
                    <p>
                        Catriona has kept her links with Gloucester as the Soprano in Bach's <i>Magnificat</i>,
                        Handel’s <i>Dixit Dominus</i>, Vivaldi’s <i>Gloria</i> and Haydn's <i>Creation</i> with Gloucester Choral Society.
                    </p>
                </div>
            </div>

            <div class="col-12 col-md-5 d-block mx-auto my-auto pb-5">
                <img :src="Image4" class="img-fluid photo-size" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
        computed: {
            HeaderImage() {
                return require(`@/assets/photos/heading.jpg`)
            },
            Image1() {
                return require(`@/assets/photos/headshot.jpg`) 
            },
            Image2() {
                return require(`@/assets/photos/GlosTheCreationOct2021.jpg`)
            },
            Image3() {
                return require(`@/assets/photos/Bach The European (Handel) May 2019.jpg`)
            },
            Image4() {
                return require(`@/assets/photos/GCSMarch2018.jpeg`)
            }

        }
    };
</script>

<style scoped>

    #about {
        background-color:var(--background-colour)
    }

    /* Small devices */
    @media (max-width: 768px) {
        .headshot-size {
            max-height: 20%;
            padding-left:30%;
            padding-right:30%;
        }
    
        .photo-size {
            max-height: 20%;
            padding-left: 30%;
            padding-right: 30%;
        }
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        .headshot-size {
            max-height: 70%;
            padding: 0;
        }

        .photo-size {
            max-height: 40%;
            padding-left: 10%;
            padding-right: 10%;
        }
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        .headshot-size {
            max-height: 50%;
            padding:2em;
        }

        .photo-size {
            max-height:30%;
            padding: 2em;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        .headshot-size {
            max-height: 50%;
            padding: 2em;
        }
        .photo-size {
            max-height: 30%;
            padding: 2em;
        }
    }

</style>