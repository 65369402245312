import Info from '../components/Info.vue'
import Media from '../components/Media.vue'
import Repertoire from '../components/Repertoire.vue'

const routes =
    [
        {
            path: '/',
            name: 'Info',
            component: Info
        },
        {
            path: '/Media',
            name: 'Media',
            component: Media
        },
        {
            path: '/Repertoire',
            name: 'Repertoire',
            component: Repertoire
        }
    ]

export default routes