import Vue from 'vue'

import { BootstrapVue, IconsPlugin, CarouselPlugin } from 'bootstrap-vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
library.add(faFacebook, faTwitter, faInstagram, faEnvelope)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(CarouselPlugin)

import VueResizeText from 'vue-resize-text';
Vue.use(VueResizeText)

import VueRouter from 'vue-router'
import routes from './router'

Vue.use(VueRouter);

const router = new VueRouter({
    routes: routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})

new Vue({
    router: router,
    render: h => h(App),
}).$mount('#app')
